import { get, post } from "common/fetcher";
import { withConfiguration } from "./config";

export const healthApiTest = () => {
  return withConfiguration(async config => {
    const endpoint = `${config["api"]}/health`;
    return get(endpoint, undefined);
  });
};

export const login = (login, password) => {
  return withConfiguration(async config => {
    const endpoint = `${config["api"]}/auth`;
    return post(endpoint, { login, password });
  });
};

export const resend = login => {
  return withConfiguration(async config => {
    const endpoint = `${config["api"]}/send-password`;
    return post(endpoint, { login, source: "LPP" });
  });
};

export const reset = (login, password, newPassword) => {
  return withConfiguration(async config => {
    const endpoint = `${config["api"]}/reset-password`;
    return post(endpoint, { login, password, newPassword });
  });
};

export const fetchCustomerConfig = (customerID, authorization) => {
  return withConfiguration(async config => {
    const endpoint = `${config["api"]}`;
    return post(
      `${endpoint}/customer/${customerID}`,
      { id: customerID },
      { authorization }
    );
  });
};

export const saveCustomerConfig = (customerID, body, authorization) => {
  return withConfiguration(async config => {
    const endpoint = `${config["api"]}`;
    return post(`${endpoint}/save-customer/${customerID}`, body, {
      authorization
    });
  });
};

export const fetchCustomers = (
  authorization,
  query,
  column,
  desc,
  clientsPerPage,
  page
) => {
  return withConfiguration(async config => {
    const endpoint = `${config["api"]}`;
    return post(
      `${endpoint}/customers`,
      { query, column, desc, clientsPerPage, page },
      { authorization }
    );
  });
};

export const getToken = authorization => {
  return withConfiguration(async config => {
    const endpoint = `${config["api"]}`;
    return get(`${endpoint}/productToken`, {}, { authorization });
  });
};

export const addChronoProduct = (
  authorization,
  uid,
  codeMin,
  codeMax,
  amount,
  products,
  marge,
  fname,
  lname
) => {
  return withConfiguration(async config => {
    const endpoint = `${config["api"]}`;
    return post(
      `${endpoint}/addProduct`,
      { codeMin, codeMax, amount, products, marge, uid, fname, lname },
      { authorization }
    );
  });
};

export const addFile = (authorization, file, userid, type) => {
  return new Promise((resolve, reject) => {
    return withConfiguration(async config => {
      const endpoint = `${config["api"]}`;
      let xhr = new XMLHttpRequest();
      xhr.open("POST", `${endpoint}/addFile`, true);
      xhr.setRequestHeader("Authorization", authorization);
      xhr.send(file);
      xhr.onload = () => {
        if (JSON.parse(xhr.response).return_type)
          return withConfiguration(async config => {
            const endpoint = `${config["api"]}`;
            resolve(
              post(
                `${endpoint}/insertFile`,
                { info: xhr.response, userid, type },
                { authorization }
              )
            );
          });
      };
    });
  });
};

export const fetchCustomerFiles = (authorization, userid) => {
  return withConfiguration(config => {
    const endpoint = `${config["api"]}`;
    return post(`${endpoint}/getFiles`, { userid }, { authorization });
  });
};

export const removeRemoteFile = (authorization, fileinfos) => {
  return withConfiguration(config => {
    const endpoint = `${config["api"]}`;
    return post(`${endpoint}/removeFile`, { fileinfos }, { authorization });
  });
};

export const fetchCustomerCodes = (authorization, userid) => {
  return new Promise(resolve => {
    return withConfiguration(config => {
      const endpoint = `${config["api"]}`;
      const response = post(
        `${endpoint}/fetchCodes`,
        { userid },
        { authorization }
      );
      resolve(response);
    });
  });
};
