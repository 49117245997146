"use strict";

var _slicedToArray = require("/home/valraiso/builds/admin-e8a1e4b/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _Snackbar = _interopRequireDefault(require("@material-ui/core/Snackbar"));

var _SnackbarContent = _interopRequireDefault(require("@material-ui/core/SnackbarContent"));

var _makeStyles = _interopRequireDefault(require("@material-ui/styles/makeStyles"));

var _colors = require("@material-ui/core/colors");

var _SnackbarContext = _interopRequireDefault(require("./SnackbarContext"));

var useStyles = (0, _makeStyles.default)(function (theme) {
  return {
    success: {
      backgroundColor: _colors.green[600]
    },
    error: {
      backgroundColor: theme.palette.error.dark
    },
    info: {
      backgroundColor: theme.palette.primary.main
    },
    warning: {
      backgroundColor: _colors.amber[700]
    }
  };
});

function SnackbarProvider(props) {
  var children = props.children;
  var classes = useStyles();

  var _ref = (0, _react.useState)(""),
      _ref2 = _slicedToArray(_ref, 2),
      message = _ref2[0],
      setMessage = _ref2[1];

  var _ref3 = (0, _react.useState)(),
      _ref4 = _slicedToArray(_ref3, 2),
      status = _ref4[0],
      setStatus = _ref4[1];

  var _ref5 = (0, _react.useState)(4000),
      _ref6 = _slicedToArray(_ref5, 2),
      autoHide = _ref6[0],
      setAutoHide = _ref6[1];

  var _ref7 = (0, _react.useState)(false),
      _ref8 = _slicedToArray(_ref7, 2),
      open = _ref8[0],
      setOpen = _ref8[1];

  var openSnackbar = function openSnackbar(messageOverride, statusOverride) {
    var autoHideOverride = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 4000;
    setMessage(messageOverride);
    setStatus(statusOverride);
    setAutoHide(autoHideOverride);
    setOpen(true);
  };

  var closeSnackbar = function closeSnackbar() {
    setOpen(false);
  };

  return _react.default.createElement(_SnackbarContext.default.Provider, {
    value: {
      openSnackbar: openSnackbar,
      closeSnackbar: closeSnackbar
    }
  }, children, _react.default.createElement(_Snackbar.default, {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    open: open,
    autoHideDuration: autoHide,
    onClose: closeSnackbar,
    transitionDuration: 200
  }, _react.default.createElement(_SnackbarContent.default, {
    className: status && classes[status],
    message: _react.default.createElement("div", null, message)
  })));
}

var _default = SnackbarProvider;
exports.default = _default;