import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  cardMedia: {
    backgroundColor: theme.palette.primary.main,
    objectFit: "contain",
    width: "100%"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  }
}));

function Layout(props) {
  const { children } = props;
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Card classes={{ root: classes.card }}>
        <CardMedia
          component="img"
          classes={{ root: classes.cardMedia }}
          image="/logo.svg"
        />
        <CardContent>
          <form className={classes.form} noValidate>
            {children}
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Layout;
