"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = void 0;

var b64DecodeUnicode = function b64DecodeUnicode(str) {
  return decodeURIComponent(escape(atob(str)));
};

var parse = function parse(token) {
  try {
    var user = JSON.parse(b64DecodeUnicode(token.split(".")[1]));
    return user;
  } catch (e) {
    throw Error("Jeton de sécurité invalide");
  }
};

exports.parse = parse;