import { lazy } from "react";

import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
const Sales = lazy(() => import("./Sales"));

export const navigation = () => {
  return [
    {
      icon: MonetizationOnIcon,
      name: "Ventes",
      title: "Ventes",
      route: {
        component: Sales,
        path: "/sales"
      },
      display: () => true
    }
  ];
};
