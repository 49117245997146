import React, { useState, useEffect } from "react";

import { healthApiTest } from "../../services/api";

const Health = () => {
  const [content, setContent] = useState({});

  const testResponse = async () => {
    const response = await healthApiTest();
    setContent(response);
  };

  useEffect(() => {
    testResponse();
    const err = {
      status: 503,
      error: { code: 503, message: "Can't join server API" }
    };
    if (Object.getOwnPropertyNames(content).length === 0) setContent(err);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{JSON.stringify(content)}</div>;
};

export default Health;
