"use strict";

var _slicedToArray = require("/home/valraiso/builds/admin-e8a1e4b/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthProvider = AuthProvider;
exports.useAuth = useAuth;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _useLocalStorage = require("./useLocalStorage");

var _token = require("../token");

var AuthContext = _react.default.createContext();

function useAuth() {
  var context = _react.default.useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}

function AuthProvider(props) {
  var _ref = (0, _useLocalStorage.useLocalStorage)("user", undefined),
      _ref2 = _slicedToArray(_ref, 2),
      user = _ref2[0],
      setUser = _ref2[1];

  var _ref3 = (0, _useLocalStorage.useLocalStorage)("token", undefined),
      _ref4 = _slicedToArray(_ref3, 2),
      token = _ref4[0],
      setToken = _ref4[1];

  var login = function login(token) {
    setToken(token);
    setUser((0, _token.parse)(token));
  };

  var logout = function logout() {
    setToken(undefined);
    setUser(undefined);
  };

  var isUserAuthorized = function isUserAuthorized() {
    return true;
  };

  return _react.default.createElement(AuthContext.Provider, (0, _extends2.default)({
    value: {
      login: login,
      logout: logout,
      user: user,
      token: token,
      isUserAuthorized: isUserAuthorized
    }
  }, props));
}