import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { createMuiTheme } from "@material-ui/core/styles";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import SnackbarProvider from "common/hooks/SnackbarProvider";
import { AuthProvider } from "common/hooks/useAuth";
import { blue } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: { main: blue[900], light: blue[100], dark: "#093170" },
    secondary: { main: blue[800], light: blue[50], dark: "#0e4686" }
  },
  typography: { useNextVariants: true },
  overrides: {
    MuiListItemIcon: { root: { minWidth: "36px" } }
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
