"use strict";

var _slicedToArray = require("/home/valraiso/builds/admin-e8a1e4b/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLocalStorage = useLocalStorage;

var _react = require("react"); // https://usehooks.com/useLocalStorage/


function useLocalStorage(key, initialValue) {
  var session = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  var _ref = (0, _react.useState)(function () {
    try {
      var item = session ? window.sessionStorage.getItem(key) : window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  }),
      _ref2 = _slicedToArray(_ref, 2),
      storedValue = _ref2[0],
      setStoredValue = _ref2[1];

  var setValue = function setValue(value) {
    try {
      var valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      session ? window.sessionStorage.setItem(key, JSON.stringify(valueToStore)) : window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}