import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import { LogoutIcon } from "common/components/Icons";
import { useAuth } from "common/hooks/useAuth";

import Navigation from "./components/navigation";
import { buildRoutes, mergeNavigations } from "./services/routes";
import MainLogin from "./pages/login/Main";
import Health from "./pages/health/Health";

import { navigation as nav1 } from "./pages/customers/nav";
import { navigation as nav2 } from "./pages/sales/nav";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  title: {
    flexGrow: 1
  },
  menu: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerInner: { position: "absolute", top: 0, bottom: 0, left: 0, right: 0 },
  appBar: {
    marginLeft: drawerWidth,
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    // TODO pourquoi on est forcé d'utiliser display: "none!important" sur la media query ?
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none!important"
    }
  },
  toolbar: {
    ...theme.mixins.toolbar,
    backgroundColor: theme.palette.primary.main
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: 0,
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
  },
  content: {
    flexGrow: 1,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    padding: theme.spacing(0, 3, 3, 3)
  },
  publicContent: {
    flexGrow: 1
  },
  buttonLogout: {
    fontSize: 14,
    textTransform: "capitalize"
  },
  buttonIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1)
  }
}));

const navigations = mergeNavigations([nav1, nav2]);

const renderRoute = (route, setTitle) => {
  const { component: Component, name, ...rest } = route;
  return (
    <Route
      exact
      key={route.path}
      render={props => {
        if (setTitle && typeof setTitle === "function") setTitle(name);

        return <Component {...props} />;
      }}
      {...rest}
    />
  );
};

// TODO : Logo with/without drawer
function AuthentifiedApp(props) {
  const { container } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [title, setTitle] = useState("");
  const { user, logout } = useAuth();

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const drawer = (
    <div className={classes.drawerInner}>
      <div
        className={classes.toolbar}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img src="/logo.svg" alt="logo" />
      </div>
      <Navigation items={navigations} />
    </div>
  );

  const renderDrawer = drawerProps => {
    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper
        }}
        {...drawerProps}
      >
        {drawer}
      </Drawer>
    );
  };

  const smDrawerProps = {
    container,
    variant: "temporary",
    anchor: "left",
    open: mobileOpen,
    onClose: handleDrawerToggle,
    ModalProps: {
      keepMounted: true // Better open performance on mobile.
    }
  };

  const xsDrawerProps = {
    variant: "permanent",
    open: true
  };

  return props.location.pathname === "/health" ? (
    <Health />
  ) : user === undefined ? (
    <MainLogin />
  ) : (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar} color="secondary">
          <Toolbar>
            <IconButton
              color="primary"
              aria-label="Open drawer"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              color="inherit"
              className={classes.title}
              noWrap
            >
              {title}
            </Typography>
            <Button
              color="inherit"
              className={classes.buttonLogout}
              onClick={logout}
            >
              <LogoutIcon className={classes.buttonIcon} />
              Déconnexion
            </Button>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            {renderDrawer(smDrawerProps)}
          </Hidden>
          <Hidden xsDown implementation="css">
            {renderDrawer(xsDrawerProps)}
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Suspense fallback={<CircularProgress />}>
            {buildRoutes(navigations).map(route =>
              renderRoute(route, setTitle)
            )}
          </Suspense>
        </main>
      </div>
    </Router>
  );
}

function App(props) {
  return (
    <Router>
      <Suspense fallback={<CircularProgress />}>
        <Switch>
          <Route component={AuthentifiedApp} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
