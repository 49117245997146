import { lazy } from "react";

import AccountCircle from "@material-ui/icons/AccountCircle";
const Customers = lazy(() => import("./Customers"));
const Customer = lazy(() => import("./Customer"));

export const navigation = () => {
  return [
    {
      icon: AccountCircle,
      name: "Clients",
      title: "Clients",
      route: {
        component: Customers,
        path: "/customers"
      },
      display: () => true
    },
    {
      icon: AccountCircle,
      name: "Clients",
      title: "Clients",
      route: {
        component: Customer,
        path: "/customers/:id"
      }
    }
  ];
};
