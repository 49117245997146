import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { useAuth } from "common/hooks/useAuth";

const useStyles = makeStyles(theme => ({
  menuItem: { paddingRight: theme.spacing(1) },
  selected: { backgroundColor: theme.palette.grey[200] },
  listItemText: { color: "black" }
}));

const displayItem = user => item =>
  item.display !== undefined && item.display(user);

const getChildrenPath = children => {
  if (children[0].children) {
    return getChildrenPath(children[0].children);
  }
  return children[0].route.path;
};

const containsCurrentPath = (item, path) => {
  return (
    (item.route && item.route.path === path) ||
    (item.children &&
      item.children.some(child => containsCurrentPath(child, path)))
  );
};

const Item = ({ item, depth, history }) => {
  const classes = useStyles();
  const currentPath = history.location.pathname;

  const isOpened = containsCurrentPath(item, currentPath);
  const [open, setOpen] = useState(isOpened);
  const selected = item.route && currentPath.includes(item.route.path);
  const handleClick = () => {
    if (item.children) {
      if (!open) {
        setOpen(!open);
      }
      const to = getChildrenPath(item.children);
      history.push(to);
    }
  };
  const close = e => {
    e.preventDefault();
    setOpen(false);
  };

  const Icon = item.icon;
  const listItem = (
    <>
      <MenuItem
        onClick={handleClick}
        classes={{ root: classes.menuItem, selected: classes.selected }}
        style={{ paddingLeft: `${12 * depth}px` }}
        selected={selected}
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.listItemText
          }}
          primary={item.name}
        />
        {item.children ? (
          open ? (
            <ExpandLess onClick={close} />
          ) : (
            <ExpandMore />
          )
        ) : (
          undefined
        )}
      </MenuItem>
      {item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Navigation
            items={item.children}
            depth={depth + 1}
            history={history}
          />
        </Collapse>
      )}
    </>
  );
  return item.route ? (
    <NavLink
      to={item.route.path}
      style={{ textDecoration: "inherit", color: "inherit" }}
      activeStyle={{ backgroundColor: "red" }}
    >
      {listItem}
    </NavLink>
  ) : (
    listItem
  );
};

const Navigation = ({ items, depth = 1, ...router }) => {
  const { user } = useAuth();
  const { history } = router;
  return (
    <MenuList>
      {items.filter(displayItem(user)).map(item => (
        <Item item={item} depth={depth} key={item.name} history={history} />
      ))}
    </MenuList>
  );
};

export default withRouter(Navigation);
