import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  loginRoot: {
    margin: theme.spacing(3, 0, 2)
  }
}));

function Login(props) {
  const classes = useStyles();
  const { login, setLogin, password, setPassword, handleLogin } = props;
  return (
    <>
      <TextField
        margin="normal"
        required
        fullWidth
        id="login"
        label="Votre identifiant"
        name="login"
        autoComplete="login"
        autoFocus
        value={login}
        onChange={setLogin}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Votre mot de passe"
        type="password"
        autoComplete="password"
        value={password}
        onChange={setPassword}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        classes={{ root: classes.loginRoot }}
        // className={classes.loginRoot}
        onClick={handleLogin}
      >
        Connexion
      </Button>
    </>
  );
}

export default Login;
