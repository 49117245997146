import React, { useState } from "react";

import Layout from "./Layout";
import Login from "./Login";
import { login } from "../../services/api";
import useSnackbar from "common/hooks/useSnackbar";
import { useAuth } from "common/hooks/useAuth";

const handleLogin = (_login, password, authLogin, openSnackbar) => async e => {
  e.preventDefault();
  try {
    const response = await login(_login, password);
    const { token } = response;
    authLogin(token);
  } catch (err) {
    const message = err.message ? "Identifiants invalides" : err.message;
    console.error("in handleLogin", err);
    openSnackbar(message);
  }
};

function Main() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const { login: authLogin } = useAuth();
  const { openSnackbar } = useSnackbar();
  const _setLogin = e => {
    setLogin(e.target.value);
  };
  const _setPassword = e => {
    setPassword(e.target.value);
  };
  const _setNewPassword1 = e => {
    setNewPassword1(e.target.value);
  };
  const _setNewPassword2 = e => {
    setNewPassword2(e.target.value);
  };

  return (
    <Layout>
      <Login
        login={login}
        setLogin={_setLogin}
        password={password}
        setPassword={_setPassword}
        newPassword1={newPassword1}
        setNewPassword1={_setNewPassword1}
        newPassword2={newPassword2}
        setNewPassword2={_setNewPassword2}
        handleLogin={handleLogin(login, password, authLogin, openSnackbar)}
      />
    </Layout>
  );
}

export default Main;
