"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

var _SnackbarContext = _interopRequireDefault(require("./SnackbarContext"));

var _default = function _default() {
  if (!_SnackbarContext.default) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }

  var _ref = (0, _react.useContext)(_SnackbarContext.default),
      openSnackbar = _ref.openSnackbar,
      closeSnackbar = _ref.closeSnackbar;

  return {
    openSnackbar: openSnackbar,
    closeSnackbar: closeSnackbar
  };
};

exports.default = _default;