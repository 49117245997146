"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LogoutIcon = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _SvgIcon = _interopRequireDefault(require("@material-ui/core/SvgIcon"));

var Icon = function Icon(props) {
  return _react.default.createElement(_SvgIcon.default, (0, _extends2.default)({
    viewBox: "0 0 24 24"
  }, props), props.children);
};

var LogoutIcon = function LogoutIcon(props) {
  return _react.default.createElement(Icon, props, _react.default.createElement("path", {
    d: "M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z"
  }));
};

exports.LogoutIcon = LogoutIcon;