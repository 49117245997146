export const buildRoutes = navigations =>
  navigations.reduce((acc, nav) => {
    const { route, children, name } = nav;
    const childrenRoutes = children ? buildRoutes(children) : [];
    const routes = children ? childrenRoutes : [{ name, ...route }];
    return [...acc, ...routes];
  }, []);

export const mergeNavigations = navigations =>
  navigations.reduce((acc, nav) => [...acc, ...nav()], []);
